<template>
  <v-dialog
    v-if="modelDialogProd"
    v-model="modelDialogProd"
    width="90%"
  >
    <v-card
      width="100%"
      height="auto"
      style="overflow-x: hidden"
    >
      <div style="height: 30px; padding: 3px; background: #f46434">
        <v-icon
          style="float: right"
          color="white"
          @click="modelDialogProd = !modelDialogProd"
        >
          close
        </v-icon>
      </div>
      <div :style="{ marginTop: disabled ? '48px' : false }">
        <v-tabs v-if="produtos.length > 1">
          <v-tab
            v-for="(item, key) in produtos"
            :key="key"
            @click="abreDrawer(item)"
          >
            {{
              editando
                ? item[
                  Object.keys(item).filter(
                    (i) =>
                      i.includes("_ds_") ||
                      i.includes("_nm_fantas") ||
                      i.includes("descricao") ||
                      i.includes("_nr_client")
                  )[0]
                ]
                : `Item ${i}`
            }}
          </v-tab>
        </v-tabs>

        <v-card-title>
          <v-icon> information </v-icon>
          <span style="margin-left: -150px">
            {{
              editando
                ? this.$t("TelaManProdutos.editando")
                : this.$t("TelaManProdutos.novo")
            }}
          </span>
        </v-card-title>
        <!--         <v-form
          ref="form"
          v-model="preenchido"
          lazy-validation
          :disabled="disabled"
        > -->
        <cadastro-form
          :key="keyCadastroForm"
          :campos="campos"
          color="#fff"
        />

        <div
          class="transition-all"
          :style="{ padding: '16px' }"
        >
          <div
            v-if="loadingImages"
            class="d-flex justify-center"
          >
            <v-progress-circular
              indeterminate
              color="primary"
              width="2"
            />
          </div>
          <div
            v-if="isEditing && !imageList.length && !loadingImages"
            class="d-flex justify-center"
          >
            <span>Nenhuma imagem da receita disponível</span>
          </div>
          <v-card
            v-if="!isEditing"
            id="droparea"
            :class="{
              receitaWrap: true,
              startedDrag: isDragging,
              'transition-all': true,
            }"
            flat
            @click="openInput"
          >
            <img
              src="/imageupload.svg"
              alt="upload image"
            >
            <p class="text-h6">
              Arraste a sua imagem aqui, ou
              <span
                :style="{ color: 'var(--v-primary-base)' }"
              >busque nos arquivos</span>
            </p>
            <p class="subtitle">
              Formatos: JPG, JPEG, PNG
            </p>
          </v-card>
          <v-fade-transition
            group
            leave-absolute
            hide-on-leave
          >
            <div
              v-for="(image, key) in imageList"
              :key="`${key}-imagem`"
            >
              <CardUploadImage
                :image-info="image"
                :is-editing="isEditing"
                @remover="removeImagem(key)"
                @updateImagemPrincipal="handleImagemPrincipal(key)"
              />
            </div>
          </v-fade-transition>
          <input
            id="inputImage"
            type="file"
            :style="{ display: 'none' }"
            hidden
            multiple
            :accept="allowedTypes"
            @change="uploadImage"
          >
        </div>

        <!--         </v-form> -->
        <v-row
          v-if="!disabled"
          class="rowCentered-prod"
        >
          <v-btn
            color="primary"
            text
            @click="validate"
          >
            {{
              editando
                ? this.$t("BotoesAcao.salvar")
                : this.$t("BotoesAcao.adicionar")
            }}
          </v-btn>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import methods from "./methods";
import CardUploadImage from "@/components/CardUploadImage/CardUploadImage.vue";
import cadastroForm from "@/components/cadastroForm";
export default {
  components: { cadastroForm, CardUploadImage },
  model: { prop: "modelProp", event: "change" },
  props: {
    modelProp: {
      type: Boolean,
      require: true,
      default: false,
    },
    produtos: {
      type: Array,
      require: false,
      default: () => {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
    editando: {
      type: Boolean,
      require: true,
      default: false,
    },
    campos: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dinheiro: 0,
      preenchido: false,
      prod: {},
      validar: true,
      verificar: 0,
      keyCadastroForm: 0,
      modelDialogProd: false,
      rules: {
        required: (value) => !!value || "Esse campo precisa ser preenchido.",
      },
      opcoesTipo: [],
      imageList: [],
      allowedTypes: ["image/png", "image/jpeg", "image/jpg"],
      isDragging: false,
      isEditing: false,
      loadingImages: false,
    };
  },
  watch: {
    imageList: {
      deep: true,
      handler() {
        const selected = this.imageList.find((item) => item.nr_princ == 1);
        if (!selected) this.imageList[0].nr_princ = true;
      },
    },
    modelProp(nv) {
      this.modelDialogProd = nv;
    },
    modelDialogProd(nv) {
      this.$emit("change", nv);
    },
    editando() {
      if (!this.editando) {
        this.limpaCampos();
      }
    },
    prod: {
      handler() {
        this.insereDadosCampos();
      },
    },
    produtos() {
      this.editando ? this.abreDrawer(this.produtos[0]) : null;
    },
  },
  mounted() {
    this.editando ? this.abreDrawer(this.produtos[0]) : null;
  },
  methods: { ...methods },
};
</script>

<style lang="scss" scoped>
.receitaWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  height: 300px;
  border: 0.125rem dashed #bebebe;
  border-radius: 1rem;
  text-align: center;
  padding: 2rem;
}
.receitaWrap > img {
  width: 10rem;
}
.receitaWrap > p {
  margin: 0;
}
.title {
  font-weight: 400;
  font-size: 18px;
  color: #0000008f;
}
.subtitle {
  font-weight: 300;
  font-size: 14px;
  color: #a3a3a3;
}
.startedDrag {
  border-color: #12a2ad;
  background: #12a2ad36;
}
</style>
