<template>
  <v-card
    flat
  >
    <v-card-text :style="{ padding: '16px 0' }">
      <div class="itensCard">
        <span>
          <v-avatar
            :style="{ borderRadius: '.5rem' }"
          > 
            <v-img
              :src="imageInfo.uri"
            />
          </v-avatar>
          <span>{{ imageInfo.name }}</span>
        </span>
        <span
          class="d-flex"
          :style="{ gap: '1rem' }"
        >
          <v-switch
            v-model="imageInfo.nr_princ"
            inset
            label="Imagem principal"
            dense
            @change="$emit('updateImagemPrincipal')"
          />
          <a
            target="_blank"
            :href="imageInfo.uri"
            :download="imageInfo.name"
          > 
            <v-icon
              size="22"
              color="info darken-1"
            >
              download
            </v-icon>
          </a>
          <v-btn
            v-if="!isEditing"
            x-small
            icon
            color="#ffababcf"
            @click="$emit('remover')"
          > 
            <v-icon color="red">
              close
            </v-icon>
          </v-btn>
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    imageInfo: {
      type: Object,
      default: () => {},
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.itensCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.itensCard > span {
  display: flex;
  align-items: center;
  gap: 1rem
}
</style>